import React from 'react';
import { Col, Row } from 'shards-react';
import { Container } from "shards-react";

class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            winkels: true,
            aboutUs: false,
            contact: false,
            faq: false,
            inschrijving: false,
            webshops: false

        };
    }


    toggleHamburger = () => {
        document.querySelector('.navbar-mobile').classList.toggle('showNav');
        document.querySelector('.ham').classList.toggle('showClose');
    }

    onClickMenu(menuItem) {
        if (menuItem === 'home') {
            this.setState({
                winkels: true,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops: false
            })
        } else if (menuItem === 'deelnemers') {
            this.setState({
                winkels: false,
                aboutUs: true,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops: false
            })
        }
        else if (menuItem === 'recepten') {
            this.setState({
                winkels: false,
                aboutUs: false,
                contact: true,
                faq: false,
                inschrijving: false,
                webshops: false
            })
        }
        else if (menuItem === 'aan-het-woord') {
            this.setState({
                winkels: false,
                aboutUs: false,
                contact: false,
                faq: true,
                inschrijving: false,
                webshops: false
            })
        }
        else if (menuItem === 'inschrijving') {
            this.setState({
                winkels: false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: true,
                webshops: false
            })
        }
        else if (menuItem === 'webshops') {
            this.setState({
                winkels: false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops: true
            })
        }
    }

    componentDidMount() {
        if (localStorage.getItem('menuActive') === 'home') {
            this.setState({
                winkels: true,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops: false
            })
        } else if (localStorage.getItem('menuActive') === 'deelnemers') {
            this.setState({
                winkels: false,
                aboutUs: true,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops: false
            })
        }
        else if (localStorage.getItem('menuActive') === 'recepten') {
            this.setState({
                winkels: false,
                aboutUs: false,
                contact: true,
                faq: false,
                inschrijving: false,
                webshops: false
            })
        }
        else if (localStorage.getItem('menuActive') === 'aan-het-woord') {
            this.setState({
                winkels: false,
                aboutUs: false,
                contact: false,
                faq: true,
                inschrijving: false,
                webshops: false
            })
        }
        else if (localStorage.getItem('menuActive') === 'inschrijving') {
            this.setState({
                winkels: false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: true,
                webshops: false
            })
        }
        else if (localStorage.getItem('menuActive') === 'webshops') {
            this.setState({
                winkels: false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops: true
            })
        }
    }

    render() {

        return (
            <Container fluid>

                <Row className="header-section desktop-menu">
                    <Col sm={2} className="logo-section">
                        <a href="https://marktcom-beek.nl/"><img alt='Het logo van Beter Bij Ons' src={'https://marktcom-beek.nl/wp-content/uploads/2024/12/Jaarmarkt-Beek-LOGO-2025.png'} /></a>
                    </Col>
                    <Col sm={8} className="menu-section">


                        <a onClick={() => this.onClickMenu('home')} className={this.state.winkels ? "active-desktop-menu" : ""} href="https://marktcom-beek.nl/">Terug naar website</a>





                    </Col>
                    <Col sm={2} className="sub-logo-section">

                    </Col>
                </Row>
                <Row className="mobile-menu">
                    <Col sm={12}>
                        <a href="https://marktcom-beek.nl/"><img alt='Het logo van Beter Bij Ons' src={'https://marktcom-beek.nl/wp-content/uploads/2024/12/cropped-Jaarmarkt-Beek-LOGO-2025-horizontaal.png'} className="mobile-logo-menu" /></a>
                        <button className="ham" onClick={() => this.toggleHamburger()}></button>
                        <nav className="navbar-mobile">

                            <ul>
                                <li><a className="menuLink" onClick={() => this.toggleHamburger()} href="https://beta1.pixelpros.nl/">Terug naar website</a></li>


                            </ul>

                        </nav>
                    </Col>


                </Row>

            </Container>
        );
    }
}

export default HeaderComponent;
